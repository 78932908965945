(function ($, Drupal) {
  var videoBehavior = {
    setup: function ($modules, context) {
      var animationTime = 1200;

      $modules.each(function () {
        init($(this));
      });

      function init($module) {
        var $play = $('.js-play', $module);
        var $landing = $('.js-video-landing', $module);
        var $reveal = $('.js-video-reveal', $module);
        var $image = $('.js-video-block-image', $module);
        var $iframeVideo = $('.js-iframevideo', $module);
        var $videoIframe = $('.js-iframevideo iframe', $module);
        var provider = $module.data('video-provider');
        var isYoutube = provider === 'youtube';
        var isYouku = provider === 'youku';
        var isZentrick = provider === 'zentrick';
        var isHTML5 = provider === 'html5';
        var isIframe = provider === 'iframe';

        $module.off('video.close').on('video.close', function (event) {
          // Pause youtube
          if (!isIframe) {
            $('iframe', $module).remove();
          }
          $module.removeClass('active');
        });

        $module.off('animation.triggered').on('animation.triggered', function (event, enter) {
          if (enter) {
            $(document).trigger('videos.pause');
            // @TODO -- Update to be according to inputs in INC file
            if ($module.find('video').length) {
              $module.find('video').attr('muted', true);
            }
            if ($play.hasClass('js-play--autoplay')) {
              $play.trigger('click.play');
            }
          }
        });
        $play.off('click.play').on('click.play', function (event) {
          event.preventDefault();
          // Pass options with overlay / where to insert video content if not overlay
          var opts = {
            context: context,
            provider: provider,
            openOverlay: $play.hasClass('js-play--overlay'),
            $content: $reveal
          };

          $(document).trigger('videos.pause');
          // Triggered once video is loaded via JS and ready to show
          $reveal.off('video.loaded').on('video.loaded', function (event) {
            // When this video starts playing, let the content block handle the active state
            if ($(this).closest('.js-content-block').length) {
              $(this).closest('.js-content-block').trigger('video.playing');
            }
            $module.addClass('active');
          });

          if (!!isYoutube) {
            opts.youTubeId = $module.data('youtube-id');
          } else if (!!isZentrick) {
            opts.zentrickId = $module.data('zentrick-id');
          } else if (!!isHTML5) {
            opts.content = $module.find('video').wrap('<div />').parent().html();
          } else if (!!isYouku) {
            opts.youkuId = $module.data('youku-id');
          } else if (!!isIframe) {
            $module.addClass('active');
            $('.js-video-library-videos').addClass('iframe-active');
            $videoIframe.attr('src', $iframeVideo.data('iframe-src'));
          }
          site.videos.open(opts);

          return false;
        });

        $('.js-close', $module).on('click', function (event) {
          event.preventDefault();
          $(document).trigger('videos.pause');
          $module.trigger('video.close');
          if (isIframe) {
            $iframeVideo.data('iframe-src', $videoIframe.attr('src'));
            $videoIframe.attr('src', '');
          }
        });
      }
    }
  };

  /**
   * We initialize each type of video separately because we don't know if/when their respective libraries are loaded
   */

  $(document).on('youtubeIframeAPI.loaded', function () {
    var $elements = $('.js-video-block--v1').filter("[data-video-provider='youtube']");

    if ($elements.length) {
      videoBehavior.setup($elements, document);
    }
  });

  $(document).on('zentrick:loaded', function () {
    var $elements = $('.js-video-block--v1').filter("[data-video-provider='zentrick']");

    if ($elements.length) {
      videoBehavior.setup($elements, document);
    }
  });

  Drupal.behaviors.videoBlockV1 = {
    attach: function (context, settings) {
      var $elements = $('.js-video-block--v1').filter("[data-video-provider='html5'], [data-video-provider='youku'], [data-video-provider='iframe']");

      if ($elements.length) {
        videoBehavior.setup($elements, context);
      }
    }
  };
})(jQuery, Drupal);
